import React from 'react';
import { styled } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { ReactComponent as ChevronRightIcon } from '../../assets/images/chevron-right-icon.svg';
import { ReactComponent as ChevronLeftIcon } from '../../assets/images/chevron-left-icon.svg';
import { getStyle } from '../../questions/styleUtil';

// Define the styled button
const StyledButton = styled(({ visible, buttonType, language, ...other }) => <Button {...other} />)(({ visible, buttonType, language }) => ({
    borderRadius: '30px',
    padding: '6px 16px',
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.4px',
    fontWeight: '600',
    boxShadow: 'none',
    backgroundColor: buttonType === 'highlight' ? 'var(--colors-highlight)' : 'var(--text-lightGrey)',
    color: 'var(--colors-fontLight)',
    opacity: !visible ? 0.5 : 1,
    '&:hover': {
        boxShadow: '0px 2px 8px 0px #7B7D898F',
        backgroundColor: buttonType === 'highlight' ? 'var(--colors-highlight)' : 'var(--text-lightGrey)',
    },
    '&.Mui-disabled': {
        backgroundColor: buttonType === 'highlight' ? 'var(--colors-highlight)' : 'var(--text-lightGrey)',
        color: 'var(--colors-fontLight)', 
        opacity: 0.5,
    },
    '& .MuiButton-label': {
        ...getStyle('primary-button-label', language),
    },
}));

// PrimaryButton component
const PrimaryButton = ({ visible = false, onClick, title, iconPosition, buttonType, customIcon = null, language = null }) => {
    return (
        <StyledButton
            variant="contained"
            color="default"
            endIcon={iconPosition === 'right' && (customIcon ? customIcon : <ChevronRightIcon />)}
            startIcon={iconPosition === 'left' && (customIcon ? customIcon : <ChevronLeftIcon />)}
            onClick={() => onClick()}
            disabled={!visible}
            visible={visible}
            buttonType={buttonType}
            language={language}
        >
            {title}
        </StyledButton>
    );
};

export default PrimaryButton;
