import React from "react";
import PublishIcon from '@material-ui/icons/Publish';
import PrimaryButton from "../components/buttons/primary-button";
import { ReactComponent as SubmitIcon } from '../assets/images/icons/submit-icon.svg';
import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { goToNextPage, goToPreviousPage, onSurveySubmit, onSubmitClick } from "../redux/actions";
import { pageCompleted } from "../services/questions-service";

// QuestionActions component
function QuestionActions(props) {
    const { t } = useTranslation();  // Get translation
    const { hasPrevious, hasNext } = props; // Destructure hasPrevious and hasNext from props
    const user = useSelector(state => state.user); // Get user from redux store
    const language = useSelector(state => state.language); // Get language from redux store
    const pageRequiredQuestions = useSelector(state => state.pageRequiredQuestions); // Get pageRequiredQuestions from redux store
    const questionAnswers = useSelector(state => state.questionAnswers); // Get questionAnswers from redux store
    const pageQuestionErrors = useSelector(state => state.pageQuestionErrors); // Get pageQuestionErrors from redux store
    const dispatch = useDispatch(); // Dispatch hook

    // Function to handle previous click
    const handlePreviousClick = () => {
        console.log('clicked previous');
        dispatch(goToPreviousPage());
    };

    // Function to handle next click
    const handleNextClick = () => {
        console.log('clicked next');
        dispatch(goToNextPage());
    };

    // Function to handle submit click
    const handleSubmit = () => {
        console.log('clicked submit');
        if (pageCompleted(pageRequiredQuestions, questionAnswers, pageQuestionErrors)) {
            dispatch(onSurveySubmit(user ? user.token : null));
        } else {
            dispatch(onSubmitClick());
        }
    };

    return (
        <Box className='question-actions-container' mt={'auto'} mb={8} display={'inline-block'}>
            <Box className='question-actions' display={'flex'} gridGap={'12px'}  style={{ flexDirection: language === 'ar' ? 'row-reverse' : 'row' }}>
                <PrimaryButton visible={hasPrevious} onClick={handlePreviousClick} title={t("previous")} iconPosition={'left'} buttonType={'default'} language={language} />
                {
                    hasNext ?
                        <PrimaryButton visible={true} onClick={handleNextClick} title={t("next")} iconPosition={'right'} buttonType={'highlight'} language={language} /> :
                        <PrimaryButton visible={true} onClick={handleSubmit} title={t("submit")} iconPosition={'left'} buttonType={'highlight'} customIcon={<SubmitIcon />} language={language} />
                }
            </Box>
        </Box>
    )
}

export default QuestionActions;
